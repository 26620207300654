<template>
  <div>
    <md-table md-card table-header-color="blue" :value="items">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Список предметов</h1>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="ID">
          {{ item.itemId }}
        </md-table-cell>

        <md-table-cell md-label="Название">
          {{ item.name }}
        </md-table-cell>

        <md-table-cell md-label="Тип предмета">
          {{ itemTypesById[item.itemTypeId] }}
        </md-table-cell>

        <md-table-cell md-label="Описание" :title="item.description">
          {{ item.description | truncate(60, "...") }}
        </md-table-cell>

        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-primary"
            @click="openItemInModal(item)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Изменить</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <ItemsModal
      v-if="showModal && !!selectedItem"
      :item="selectedItem"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length <= length) {
        return text.substring(0, length);
      } else {
        return text.substring(0, length) + suffix;
      }
    },
  },

  components: {
    ItemsModal: () => import("@/components/Modals/ItemsModal"),
  },

  data() {
    return {
      selectedItem: null,
      showModal: false,
    };
  },

  computed: {
    ...mapGetters("items", ["itemTypesById"]),
    ...mapState("items", ["items"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getItemTypes();
    await this.getItems();
    this.showLoader(false);
  },

  methods: {
    ...mapActions("items", ["getItems", "getItemTypes"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    async closeModal(needUpdate) {
      this.showModal = false;
      this.selectedItem = null;

      if (needUpdate == true) {
        this.showLoader(true);
        await this.getItems();
        this.showLoader(false);
      }
    },

    openItemInModal(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
